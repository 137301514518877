<template>
	<div class="crossNavigation_page">
		<div class="top_holder"></div>
		<!-- 内容主体 -->
		<div class="container">
			<!-- 搜索框 -->
			<h2 class="top_title">搜索你想要的网址信息</h2>
			<div class="search">
				<a-icon style="font-size: 20px" type="search" />
				<input type="text" class="ipt" v-model="keyword" @keyup.enter="search" @focus="changeIptColor" @blur="removeIptColor" placeholder="请搜索关键词" /><a-icon v-show="keyword.trim()" style="font-size: 20px" type="close" @click="keyword = ''" />
			</div>
			<!-- 搜索热词 -->
			<div class="search_hot_word">
				<span class="hot_word_left"><a-icon type="fire" />热门搜索：</span>
				<span class="hot_word_item" @click="searchHotword(item)" v-for="item in hotword" :key="item">{{ item }}</span>
			</div>
			<!-- 主体 -->
			<div class="crossNavigation_body web-center">
				<div class="left_nav">
					<a-anchor :affix="false">
						<a-anchor-link :href="'#' + item.id" v-for="item in list" :key="item.id" :title="item.title" />
					</a-anchor>
				</div>
				<div class="right_container">
					<a-card v-for="item in list" :key="item.id" :title="item.title" :id="item.id" style="width: 980px; margin-bottom: 28px">
						<CrossNavList :listData="item.listData"></CrossNavList>
					</a-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CrossNavList from '../components/CrossNavList.vue';
import { crossNavCategory, crossNavList } from '@/api/extension.js';
export default {
	components: { CrossNavList },
	data() {
		return {
			keyword: '',
			isShowClose: false,
			hotword: ['亚马逊', '领星', '运营参谋', '翻译', '卖家精灵'],
			list: [],
		};
	},
	created() {
		crossNavCategory().then(({ data }) => {
			const list = Object.values(data.data);
			const promiseArr = [];
			list.forEach((i) => {
				promiseArr.push(crossNavList({ id: i.id }));
			});
			Promise.all(promiseArr).then((res) => {
				res.forEach((item, index) => {
					list[index].listData = item.data.data.list;
				});
				this.list = list;
			});
		});
	},
	mounted() {},
	methods: {
		changeIptColor(e) {
			e.target.parentNode.style.border = '1px solid #4C84FF';
		},
		removeIptColor(e) {
			e.target.parentNode.style.border = '0px solid #4C84FF';
		},
		search() {
			this.$router.push({
				path: '/extension/search',
				query: { keyword: this.keyword, type: 'nav' },
			});
		},
		searchHotword(item) {
			this.keyword = item;
			this.search();
		},
	},
};
</script>

<style lang="less" scoped>
@import '@/style/mixin.less';
.crossNavigation_page {
	.top_holder {
		height: 60px;
		background-color: #fff;
	}
	.container {
		min-height: calc(100vh - 60px);
		padding-top: 60px;
		background: url('~@/assets/img/extension/crossNavigation/banner.png') center top/100% 550px no-repeat;
		background-color: #f9faff;
		.top_title {
			font-size: 30px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #101433;
			text-align: center;
		}
		.search {
			width: 520px;
			height: 52px;
			margin: 24px auto 14px;
			box-shadow: 0px 2px 16px 0px rgba(194, 218, 255, 0.18);
			border-radius: 4px;
			background-color: #fff;
			.flex(center);
			.ipt {
				height: 100%;
				width: 400px;
				margin: 0px 15px;
			}
		}
		.search_hot_word {
			width: 520px;
			margin: 14px auto 60px;
			color: #616b8b;
			.flex(flex-start);
			.hot_word_item {
				margin-right: 20px;
				cursor: pointer;
				&:hover {
					color: @blue;
				}
			}
		}
		.crossNavigation_body {
			.flex(@align:flex-start);
			.left_nav {
				position: sticky;
				top: 0;
			}
		}
	}
	::v-deep .ant-card-head-title {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		color: #374567;
	}
	::v-deep .ant-anchor-link-active {
		background-color: #f3f7ff;
	}

	.container {
		.crossNavigation_body {
			.left_nav {
				::v-deep .ant-anchor-wrapper {
					padding: 8px 0;
					border-radius: 4px;
					width: 200px;
					&::-webkit-scrollbar {
						display: none;
					}
					.ant-anchor {
						padding: 0;
						.ant-anchor-ink {
							display: none;
						}
						.ant-anchor-link {
							padding: 0;
							padding-left: 24px;
							a {
								height: 40px;
								line-height: 40px;
							}
							&.ant-anchor-link-active {
								position: relative;
								&::before {
									position: absolute;
									content: '';
									left: 0;
									top: 0;
									height: 100%;
									width: 2px;
									background-color: #4c84ff;
								}
							}
						}
					}
				}
			}
			.right_container {
				::v-deep .ant-card {
					border-radius: 4px;
					border: 1px solid #e4eaf7;
				}
			}
		}
	}
}
</style>
